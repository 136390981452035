import { api } from '../api-client';

export const getCheckoutApi = async cartId => {
	var checkCartId = cartId ? cartId : 0;

	const checkout = await api(`/checkout/${checkCartId}`, {
		method: 'GET',
	});

	return checkout;
};

export const getCountries = async () => {
	var result = await api(`/checkout/countries/list`, {
		method: 'GET',
	});

	var result = [
		{
			id: 226,
			country: 'United States',
			country_iso2: 'US',
			country_iso3: 'USA',
			states: [
				{
					id: 1,
					state: 'Alabama',
					state_abbreviation: 'AL',
					country_id: 226,
				},
				{
					id: 2,
					state: 'Alaska',
					state_abbreviation: 'AK',
					country_id: 226,
				},
				{
					id: 3,
					state: 'American Samoa',
					state_abbreviation: 'AS',
					country_id: 226,
				},
				{
					id: 4,
					state: 'Arizona',
					state_abbreviation: 'AZ',
					country_id: 226,
				},
				{
					id: 5,
					state: 'Arkansas',
					state_abbreviation: 'AR',
					country_id: 226,
				},
				{
					id: 6,
					state: 'Armed Forces Africa',
					state_abbreviation: 'AE',
					country_id: 226,
				},
				{
					id: 7,
					state: 'Armed Forces Americas',
					state_abbreviation: 'AA',
					country_id: 226,
				},
				{
					id: 8,
					state: 'Armed Forces Canada',
					state_abbreviation: 'AE',
					country_id: 226,
				},
				{
					id: 9,
					state: 'Armed Forces Europe',
					state_abbreviation: 'AE',
					country_id: 226,
				},
				{
					id: 10,
					state: 'Armed Forces Middle East',
					state_abbreviation: 'AE',
					country_id: 226,
				},
				{
					id: 11,
					state: 'Armed Forces Pacific',
					state_abbreviation: 'AP',
					country_id: 226,
				},
				{
					id: 12,
					state: 'California',
					state_abbreviation: 'CA',
					country_id: 226,
				},
				{
					id: 13,
					state: 'Colorado',
					state_abbreviation: 'CO',
					country_id: 226,
				},
				{
					id: 14,
					state: 'Connecticut',
					state_abbreviation: 'CT',
					country_id: 226,
				},
				{
					id: 15,
					state: 'Delaware',
					state_abbreviation: 'DE',
					country_id: 226,
				},
				{
					id: 16,
					state: 'District of Columbia',
					state_abbreviation: 'DC',
					country_id: 226,
				},
				{
					id: 17,
					state: 'Federated States Of Micronesia',
					state_abbreviation: 'FM',
					country_id: 226,
				},
				{
					id: 18,
					state: 'Florida',
					state_abbreviation: 'FL',
					country_id: 226,
				},
				{
					id: 19,
					state: 'Georgia',
					state_abbreviation: 'GA',
					country_id: 226,
				},
				{
					id: 20,
					state: 'Guam',
					state_abbreviation: 'GU',
					country_id: 226,
				},
				{
					id: 21,
					state: 'Hawaii',
					state_abbreviation: 'HI',
					country_id: 226,
				},
				{
					id: 22,
					state: 'Idaho',
					state_abbreviation: 'ID',
					country_id: 226,
				},
				{
					id: 23,
					state: 'Illinois',
					state_abbreviation: 'IL',
					country_id: 226,
				},
				{
					id: 24,
					state: 'Indiana',
					state_abbreviation: 'IN',
					country_id: 226,
				},
				{
					id: 25,
					state: 'Iowa',
					state_abbreviation: 'IA',
					country_id: 226,
				},
				{
					id: 26,
					state: 'Kansas',
					state_abbreviation: 'KS',
					country_id: 226,
				},
				{
					id: 27,
					state: 'Kentucky',
					state_abbreviation: 'KY',
					country_id: 226,
				},
				{
					id: 28,
					state: 'Louisiana',
					state_abbreviation: 'LA',
					country_id: 226,
				},
				{
					id: 29,
					state: 'Maine',
					state_abbreviation: 'ME',
					country_id: 226,
				},
				{
					id: 30,
					state: 'Marshall Islands',
					state_abbreviation: 'MH',
					country_id: 226,
				},
				{
					id: 31,
					state: 'Maryland',
					state_abbreviation: 'MD',
					country_id: 226,
				},
				{
					id: 32,
					state: 'Massachusetts',
					state_abbreviation: 'MA',
					country_id: 226,
				},
				{
					id: 33,
					state: 'Michigan',
					state_abbreviation: 'MI',
					country_id: 226,
				},
				{
					id: 34,
					state: 'Minnesota',
					state_abbreviation: 'MN',
					country_id: 226,
				},
				{
					id: 35,
					state: 'Mississippi',
					state_abbreviation: 'MS',
					country_id: 226,
				},
				{
					id: 36,
					state: 'Missouri',
					state_abbreviation: 'MO',
					country_id: 226,
				},
				{
					id: 37,
					state: 'Montana',
					state_abbreviation: 'MT',
					country_id: 226,
				},
				{
					id: 38,
					state: 'Nebraska',
					state_abbreviation: 'NE',
					country_id: 226,
				},
				{
					id: 39,
					state: 'Nevada',
					state_abbreviation: 'NV',
					country_id: 226,
				},
				{
					id: 40,
					state: 'New Hampshire',
					state_abbreviation: 'NH',
					country_id: 226,
				},
				{
					id: 41,
					state: 'New Jersey',
					state_abbreviation: 'NJ',
					country_id: 226,
				},
				{
					id: 42,
					state: 'New Mexico',
					state_abbreviation: 'NM',
					country_id: 226,
				},
				{
					id: 43,
					state: 'New York',
					state_abbreviation: 'NY',
					country_id: 226,
				},
				{
					id: 44,
					state: 'North Carolina',
					state_abbreviation: 'NC',
					country_id: 226,
				},
				{
					id: 45,
					state: 'North Dakota',
					state_abbreviation: 'ND',
					country_id: 226,
				},
				{
					id: 46,
					state: 'Northern Mariana Islands',
					state_abbreviation: 'MP',
					country_id: 226,
				},
				{
					id: 47,
					state: 'Ohio',
					state_abbreviation: 'OH',
					country_id: 226,
				},
				{
					id: 48,
					state: 'Oklahoma',
					state_abbreviation: 'OK',
					country_id: 226,
				},
				{
					id: 49,
					state: 'Oregon',
					state_abbreviation: 'OR',
					country_id: 226,
				},
				{
					id: 50,
					state: 'Palau',
					state_abbreviation: 'PW',
					country_id: 226,
				},
				{
					id: 51,
					state: 'Pennsylvania',
					state_abbreviation: 'PA',
					country_id: 226,
				},
				{
					id: 52,
					state: 'Puerto Rico',
					state_abbreviation: 'PR',
					country_id: 226,
				},
				{
					id: 53,
					state: 'Rhode Island',
					state_abbreviation: 'RI',
					country_id: 226,
				},
				{
					id: 54,
					state: 'South Carolina',
					state_abbreviation: 'SC',
					country_id: 226,
				},
				{
					id: 55,
					state: 'South Dakota',
					state_abbreviation: 'SD',
					country_id: 226,
				},
				{
					id: 56,
					state: 'Tennessee',
					state_abbreviation: 'TN',
					country_id: 226,
				},
				{
					id: 57,
					state: 'Texas',
					state_abbreviation: 'TX',
					country_id: 226,
				},
				{
					id: 58,
					state: 'Utah',
					state_abbreviation: 'UT',
					country_id: 226,
				},
				{
					id: 59,
					state: 'Vermont',
					state_abbreviation: 'VT',
					country_id: 226,
				},
				{
					id: 60,
					state: 'Virgin Islands',
					state_abbreviation: 'VI',
					country_id: 226,
				},
				{
					id: 61,
					state: 'Virginia',
					state_abbreviation: 'VA',
					country_id: 226,
				},
				{
					id: 62,
					state: 'Washington',
					state_abbreviation: 'WA',
					country_id: 226,
				},
				{
					id: 63,
					state: 'West Virginia',
					state_abbreviation: 'WV',
					country_id: 226,
				},
				{
					id: 64,
					state: 'Wisconsin',
					state_abbreviation: 'WI',
					country_id: 226,
				},
				{
					id: 65,
					state: 'Wyoming',
					state_abbreviation: 'WY',
					country_id: 226,
				},
			],
		},
		{
			id: 38,
			country: 'Canada',
			country_iso2: 'CA',
			country_iso3: 'CAN',
			states: [
				{
					id: 66,
					state: 'Alberta',
					state_abbreviation: 'AB',
					country_id: 38,
				},
				{
					id: 67,
					state: 'British Columbia',
					state_abbreviation: 'BC',
					country_id: 38,
				},
				{
					id: 68,
					state: 'Manitoba',
					state_abbreviation: 'MB',
					country_id: 38,
				},
				{
					id: 69,
					state: 'Newfoundland and Labrador',
					state_abbreviation: 'NL',
					country_id: 38,
				},
				{
					id: 70,
					state: 'New Brunswick',
					state_abbreviation: 'NB',
					country_id: 38,
				},
				{
					id: 71,
					state: 'Nova Scotia',
					state_abbreviation: 'NS',
					country_id: 38,
				},
				{
					id: 72,
					state: 'Northwest Territories',
					state_abbreviation: 'NT',
					country_id: 38,
				},
				{
					id: 73,
					state: 'Nunavut',
					state_abbreviation: 'NU',
					country_id: 38,
				},
				{
					id: 74,
					state: 'Ontario',
					state_abbreviation: 'ON',
					country_id: 38,
				},
				{
					id: 75,
					state: 'Prince Edward Island',
					state_abbreviation: 'PE',
					country_id: 38,
				},
				{
					id: 76,
					state: 'Quebec',
					state_abbreviation: 'QC',
					country_id: 38,
				},
				{
					id: 77,
					state: 'Saskatchewan',
					state_abbreviation: 'SK',
					country_id: 38,
				},
				{
					id: 78,
					state: 'Yukon Territory',
					state_abbreviation: 'YT',
					country_id: 38,
				},
			],
		},
	];
	return result;
};

export const setBillingAddress = async (checkoutId, requestBody) => {
	const address = await api(`/checkout/${checkoutId}/billingaddress`, {
		method: 'POST',
		body: JSON.stringify(requestBody),
	});
	return address;
};

export const setShippingAddress = async (checkoutId, requestBody) => {
	const address = await api(`/checkout/${checkoutId}/consignments`, {
		method: 'POST',
		body: JSON.stringify(requestBody),
	});
	return address;
};

export const putShippingAddressOrShippingOption = async (checkoutId, consignmentId, requestBody) => {
	const consignment = await api(`/checkout/${checkoutId}/consignments/${consignmentId}`, {
		method: 'PUT',
		body: JSON.stringify(requestBody),
	});
	return consignment;
};

export const setCouponCode = async (checkoutId, requestBody) => {
	const coupon = await api(`/checkout/${checkoutId}/coupons`, {
		method: 'POST',
		body: JSON.stringify(requestBody),
	});
	return coupon;
};

export const createOrder = async (checkoutId, requestBody) => {
	const order = await api(`/checkout/${checkoutId}/createorder`, {
		method: 'POST',
		body: JSON.stringify(requestBody),
	});
	return order;
};

export const updatePayment = async (orderId, requestBody) => {
	const order = await api(`/checkout/${orderId}/updatepayment`, {
		method: 'POST',
		body: JSON.stringify(requestBody),
	});
	return order;
};
